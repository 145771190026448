
/* font--------------------------------------------------------------------- */

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    src: url('./font/SpoqaHanSansNeo-Light.eot');
    src: local(※), local(※),
        url('./font/SpoqaHanSansNeo-Light.eot') format('embedded-opentype'),
        url('./font/SpoqaHanSansNeo-Light.woff2') format('woff2'),
        url('./font/SpoqaHanSansNeo-Light.woff') format('woff'),
        url('./font/SpoqaHanSansNeo-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
@font-face {
    font-family: 'Spoqa Han Sans Neo';
    src: url('./font/SpoqaHanSansNeo-Regular.eot');
    src: local(※), local(※),
        url('./font/SpoqaHanSansNeo-Regular.eot') format('embedded-opentype'),
        url('./font/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
        url('./font/SpoqaHanSansNeo-Regular.woff') format('woff'),
        url('./font/SpoqaHanSansNeo-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Spoqa Han Sans Neo';
    src: url('./font/SpoqaHanSansNeo-Medium.eot');
    src: local(※), local(※),
        url('./font/SpoqaHanSansNeo-Medium.eot') format('embedded-opentype'),
        url('./font/SpoqaHanSansNeo-Medium.woff2') format('woff2'),
        url('./font/SpoqaHanSansNeo-Medium.woff') format('woff'),
        url('./font/SpoqaHanSansNeo-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Spoqa Han Sans Neo';
    src: url('./font/SpoqaHanSansNeo-Bold.eot');
    src: local(※), local(※),
        url('./font/SpoqaHanSansNeo-Bold.eot') format('embedded-opentype'),
        url('./font/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
        url('./font/SpoqaHanSansNeo-Bold.woff') format('woff'),
        url('./font/SpoqaHanSansNeo-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }


  @font-face {
    font-family: 'Plak W02 Black Condensed';
    src: url('./font/Plak W02 Black Condensed.eot');
    src: local(※), local(※),
        url('./font/Plak W02 Black Condensed.eot') format('embedded-opentype'),
        url('./font/Plak W02 Black Condensed.woff2') format('woff2'),
        url('./font/Plak W02 Black Condensed.woff') format('woff'),
        url('./font/Plak W02 Black Condensed.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }



*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    /* overflow: hidden; */
    overflow-x: hidden;
    font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
    color: #121212;
}

.font-family
{
    font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
}

.text-kor{
    font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    z-index: 1;
}

.text-eng{
    font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
    z-index: 1;
}
.empty-line
{
    height: 0%;
    visibility: hidden;
    margin: 0;
    border: 0;
}

#menu-bar
{
    width: 100%;
    z-index: 99;
    position: absolute;
    text-align: center;
    margin-top: 30px;
    height: 28px;
}

#menu-bar img{
    margin-right: 27px;
    margin-left: 27px;
    height: 100%;
    cursor: pointer;
}

#menu-bar-home{
    position: absolute;
    left: 8px;

}

#main-menu-bar{
    white-space: nowrap;
}



#home-window
{
position: relative;
min-width: 768px;
min-height: 100vh;
}

#main-vb-img{
    text-align: center;
    overflow: hidden;
}
#main-vb-img img{
    position: absolute;
    height: 100%;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
#main-vb-img-mobile{
    display: none;
}

#home-copyright-text
{
    display: none;
    font-style: normal;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 0.75rem;
}



#home-vb-img img{
    width: 100%;
}

#poster-window
{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    display:flex;
    
    align-items: center;
    overflow-x: hidden;
}

#poster-window div{
    position: relative;
}

.poster-horizontal {
    flex-direction: row;
    justify-content: start;
    padding-left: 8%;
}

.poster-horizontal div{
    max-width: 30%;
    margin: 2.5%;
    margin-left: 18%;
    display: block;
}

.poster-vertical {
    flex-direction: column;
    justify-content: center;
}

.poster-vertical div{
    max-height: 60%;
    margin: 1.5% 10%;
    display: block;
}

#poster-window .poster-image{
    display: block;
    max-width:100%;
    max-height:100%;
    width:100%;
    height:100%;
    object-fit: contain;
}

#poster-window #poster-close{
    position: absolute;
    /* right: 5%; */
    width: 11.6%;
    cursor: pointer;
    top: 92%;
    right: 3%;
    transform: translate(-20%, -40%);
}

#tps-window
{
    background-color: #9affd8;
    width: 100%;
    height: 100%;
    position: absolute;
}

#tps-content{
    width: 100%;
    margin: 0 auto;
    font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
    color: #121212;
    font-size: 14.4px;
    line-height: 22px;
    max-width: 1400px;
    padding-left: 120px;
    padding-right: 120px;
    margin-top:188px;
    margin-bottom: 176px;
    padding-bottom: 30px;
    text-align: center;
}

#tps-content-logo-image{
    width: 39.2%;
    margin-left:6.6%;
    margin-bottom: 24px;
    text-align: left;
}
#tps-content-logo-image img{
    width: 94px;
}
#tps-text-kor{
    position: relative;
    display: inline-block;
    text-align: left;
    width: 39.2%;
    font-weight: 500;
    margin-right:4%;
    line-height: 22px;
    margin-bottom: 20px;
}

#tps-text-eng{
    position: relative;
    vertical-align: top;
    text-align: left;
    display: inline-block;
    width: 39.2%;
    line-height: 22px;
    margin-left:4%;
    margin-bottom: 20px;
}

#tps-text-insta{
    text-align: left;
    margin-top: 92px;
    margin-left: 6.6%;
    margin-bottom:20px;
    line-height: 18px;
}
#tps-text-insta img{
    width: 20px;
    height: 20px;
}
#tps-text-insta a{
    color: #121212;
    font-weight: 500;

}







#vb-window
{
   
    background-image:url(./img/vb20223.png);
    background-position: 50% 50%;
    width: 100%;
    height: auto;
    position: absolute;
    min-height: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
}

#vb-menu-bar
{
    font-family: 'Plak W02 Black Condensed',Arial, Helvetica, 'sans-serif' !important;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-top: 96px;
    margin-bottom: 32px;
    font-size: 1.25rem;
}

.vb-menu-bar-item
{
    font-family: 'Plak W02 Black Condensed',Arial, Helvetica, 'sans-serif'!important;
    cursor: pointer !important;
    color: #121212;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 800;

    
}

.vb-menu-bar-blank
{
    min-width: 2rem;
}

.vb-menu-bar-item-selected
{
    color: #602add !important;
}

#vb-about-content
{
    display: flex;
    font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #121212;
    margin-top: 40px;
    margin-bottom: 90px;
    max-width:1360px;
}

#vb-about-content-wrapper{
    float: left;
    margin-left: 20px;
    width:500px;
}
#vb-about-content-letter{
    margin-bottom: 100px;
}
#vb-about-content-letter img{
    width: 380px;
    margin-bottom: 10px;
}
#vb-about-content-letter img:nth-child(1){
    width: 230px;
}
#vb-about-content-letter img:nth-child(2){
    margin-left: 0px;
}

#vb-about-content-specific img{
    width: 180px;
    margin-right: 10px;
    margin-top: 40px;
    margin-bottom: 60px;
}
#vb-about-content-image{
    clear: both;
}
#vb-about-content-image img{
    margin-left:-16px;
    margin-bottom: 32px;
    width: 120px;
    z-index: 2;
}
#vb-about-text-wrapper{
    float: right;

    width: 360px;
}
#vb-about-text-kor
{
    font-weight: 500;
}
#vb-about-text-title-kor
{
    margin-bottom: 18px;
    font-weight: 700;
}

#vb-about-text-eng
{
    margin-top: 42px;
    font-weight: 500;
}
#vb-about-text-title
{
    margin-bottom: 18px;
    font-weight: 700;
}

#vb-about-extra-text
{
    width: 420px;
    font-weight: 400;
    margin-left: 20px;
    font-size:12px;
    line-height: 18px;
}

#vb-about-extra-text span{
    font-weight: 700;
    padding-right: 10px;
}

#vb-artist-content
{
    margin-top: 30px;
    /* margin-left: 8%;
    margin-right: 8%; */
    background-color:transparent;
    
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
   /* padding-top: 60px;*/
    padding-left: 8%;
    padding-right: 8%;
    height:100%;
    height: fit-content;
    min-height: calc(100vh - 121px);
    width: 100%;
    max-width: 100rem;

    padding-left: 8rem;
    padding-right: 8rem;

    overflow-x: hidden;
    overflow: hidden;
}

#vb-artist-content-container{
    padding: 0;
    /* border: 1px solid red; */
    padding-bottom: 60px;
}

#vb-artist-content-image-container{
    display: flex;
    flex-wrap: wrap;
}

.vb-artist-content-image-box{
    flex: 0 0 auto;
    /* 16 / 0.333 */
    /* 15.666 / 0.5 */
    /* 15 / 0.833 */
    width: 13.28%;
    margin: 0.5%;
    position:relative;
    overflow:hidden;
    cursor: pointer;
}

.vb-artist-content-image-box::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.vb-artist-content-image-text{
    position: absolute;
    width:100%;
    height: 100%;
    background-color: #000000;
    display:none;
}

.vb-artist-content-image {
    /* border: 1px solid black; */
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}



/* Video Bites - ARTIST - INFO */

#vb-artist-content-info-container{
    padding: 30px;
    padding-top: 60px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin-top:30px;

}

#vb-artist-content-info-back {

    position: absolute;
    margin-top: -50px;
    color: #2d2d2d;
    font-weight: 400;
    font-size: 15px;
    cursor: pointer;
}
#vb-artist-content-info-back img{
    padding-bottom: 2px;
    vertical-align: middle;
    width: 40px;
    height: auto;
}
#vb-artist-content-info-box{
    flex: 0 0 auto;
    width: 33%;
    position: relative;
    padding-right: 2.1rem;
    height: 100%;
}

#vb-artist-content-info-title{

    float: left;
    color: #000000;
    font-style: normal;
    font-weight: normal;
    font-size: 2.125rem;
    line-height: 2.125rem;
    margin-bottom: 30px;
    margin-top: 4px;
}

#vb-artist-content-info-subtitle{
    vertical-align: text-top;
    float: right;
    color: #121212;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
}

#vb-artist-content-info-text{
    display: block;
}

#vb-artist-content-info-birth{
    clear: both;
    color: #7A7E84;
    font-weight: 300;
    font-size: 13px;

    padding-top: 40px;
    margin-bottom: 30px;

}

#vb-artist-content-info-about{
    clear: both;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5rem;
    margin-bottom: 64px;
}

#vb-artist-content-info-history{
    color: #7A7E84;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;
}

#vb-artist-content-info-homepage{

    color: #7A7E84;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-top: 20px;
    word-wrap: break-word;
}
#vb-artist-content-info-homepage2{
    color: #7A7E84;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 20px;
    word-wrap: break-word;
}

#vb-artist-content-works-box{
    flex: 0 0 auto;
    width: 66%;
    /* border: 1px solid blue; */
    height: 100%;
    padding-left: 2rem;
    padding-top: 0rem;
    display: flex;
    flex-wrap: wrap;
    /* align-items: stretch; */
    border-left: 1.2px solid #dee1e3;
    border-top: none;
}

#vb-artist-content-works-main-image{
    width:100%;
    object-fit: contain;
    padding-top:56.25%; /* 56.25% * 70% */;
    position: relative;
}

#vb-artist-content-works-main-image img{
    width: 100%;
    object-fit: contain;
}

#vb-artist-content-works-main-image iframe{
    width: 100%;
    object-fit: contain;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

#vb-artist-content-works-main-info{
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    display: flex;
}

#vb-artist-content-works-main-reservation{
    display: none;
    font-size: 1rem;
    font-weight: 800;

}

#vb-artist-content-works-booth{
display: none;
}

#vb-artist-content-works-timetable{
display: none;
}

#vb-artist-content-works-main-caption{
    align-items: flex-end;
    width: 100%;
    color: #121212;
    margin-top: 20px;
    padding-bottom: 20px;
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: normal;
}

#vb-artist-content-works-text{
    color: #121212;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}

#vb-artist-content-works-image-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.vb-artist-content-works-image-item{
    width: 100%;
    flex: 0 0 auto;
    width: 30%;
    margin-right: 3.33%;
    margin-bottom: 3.33%;
    position: relative;
    cursor: pointer;
}

.vb-artist-content-works-image-item::after {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.vb-artist-content-works-image-item img{
    /* border: 1px solid black; */
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit:contain;
}

/* Video Bites - ARTIST - INFO - SPECIFIC */

#vb-artist-content-works-specific-box{
    flex: 0 0 auto;
    width: 66%;
    /* border: 1px solid blue; */
    height: 100%;
    padding-left: 2rem;
    padding-top: 0rem;
    display: flex;
    flex-wrap: wrap;
    /* align-items: stretch; */
    border-left: 1.2px solid #dee1e3;
    border-top: none;
    margin-bottom: 40px;

}

#vb-artist-content-works-specific-image{
    width: 100%;
    object-fit: fill;
    margin-bottom: 30px;
}

#vb-artist-content-works-specific-image img{
    width: 100%;
    object-fit: fill;
}

#vb-artist-content-works-specific-info{
    flex-direction: column;
    width: 68%;
    display: flex;
    padding-right: 10px;
}

#vb-artist-content-works-specific-contact-box{
    width: 32%;
    text-align: right;
    display: flex;
    justify-content:flex-end;
}

#vb-artist-content-works-specific-contact{
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5rem;
    cursor: pointer;
}

#vb-artist-content-works-specific-contact a{
    color: black;
    font-weight: bold;
    font-style: normal;
    text-decoration:none;
}
#vb-artist-content-works-specific-contact a:hover{
    color: #5f22ec;
    text-decoration: underline;
}
#vb-artist-content-works-specific-contact img{
    margin-right: 4px;
}

#vb-artist-content-works-specific-caption{
    font-size: 13px;
    line-height: 1.125rem;
    font-weight: normal;
}

#vb-artist-content-works-specific-price{
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: normal;
    color: #000000;
    margin-top: 30px;
}



/* VB - ARCHIVE */

#vb-archive-content-container{
    font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
}

#vb-archive-content-title{
    font-family: 'Plak W02 Black Condensed',Arial, Helvetica, 'sans-serif';
    border-bottom: 2px solid #121212;
    margin-top: 30px;
    flex: 0 0 auto;
    width: 100%;
    font-style: normal;
    font-size:40px;
    line-height: 44px;
    margin-bottom: 30px;

}

.vb-archive-content-item{
    flex: 0 0 auto;
    /* 16 / 0.333 */
    /* 15.666 / 0.5 */
    /* 15 / 0.833 */
    width: 100%;
    margin-bottom: 2rem;
    position:relative;
    overflow:hidden;
    /* border: 1px solid black; */
    text-decoration: none;
    color: #121212;
}
.vb-archive-content-item:hover{
    color: #7A7E84;
}
.vb-archive-content-image-box {
    position: relative;
    object-fit: cover;
    /* border: 1px solid gray; */
}
.vb-archive-content-image-box::after {
    content: "";
    display: block;
} 

.vb-archive-content-image{
    cursor: pointer;
    float: left;
    width: 300px;
    height: 200px;
    margin-right: 30px;
    object-fit: cover;
}


.vb-archive-content-item-title{
    color: #7A7E84;
    margin-left: 40px;
    font-size: 13px;
    font-weight: 400;
    margin-bottom:20px;
}

.vb-archive-content-item-date{
    margin-top: 24px;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.vb-archive-content-item-platform{
    color: #7A7E84;
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2rem;
}

/* VB - PROGRAM */

#vb-program-content
{
    margin-top: 0px;
    background-color: white;
    padding-left: 1%;
    padding-right: 1%;
    height:100%;
    height: fit-content;
    font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
    margin-bottom: 80px;

}
#vb-program-contents-box{
    max-width: 1100px;
}
#vb-program-title{
    font-family: 'Plak W02 Black Condensed',Arial, Helvetica, 'sans-serif';
    font-size: 40px;
   padding-top: 20px;
    margin-bottom: 20px;
    color: #121212;
}
#vb-program-banner{
    background-image: url(./img/vbbanner.png);
    background-position:50% 50%;
    height: 180px;
}
#vb-program-contact{
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 64px;
}
#vb-program-contact a{
    color: #121212;
}
#vb-program-box{
    border-top: 2px solid #000000;
    padding-left: 20px;
}
#vb-program-category{
    float: right;
    width: 144px;
    height: 26px;
    background-color: palegreen;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    line-height: 26px;
}
#vb-program-name{
    display:flex;
    margin-top:32px;
}
#vb-program-name p{
    display:inline;
    font-size: 20px;
    font-weight: 500;
    margin-top: 4px;
    margin-left: 5px;

}
#vb-program-box img{
    width: 32px;
    height: 32px;
    padding-bottom: 7px;
    padding-right: 2px;

}
#vb-program-contents{
    width: 50%;
    font-size: 15px;
    margin-top: 20px;
    line-height: 22px;
    padding-left: 34px;
}

.perform{
    font-size: 14px;
    font-weight: 500;
    color: #121212;
}

#vb-program-info{
    font-size: 12.4px;
    color: #121212;
    width:900px;
    padding-left: 34px;
    margin-top: 36px;
    margin-bottom: 20px;
}
#vb-program-info p{
    margin-bottom: 16px;
}
#vb-program-info li{
    display: inline-block;
    vertical-align: text-top;
    width: 360px;
}
#vb-program-info span{
    font-weight: 700;
}











/*********************************************************************************************/










/* #### Tablets Portrait or Landscape #### */
/* (max-width: 767px) */
@media screen and (max-width: 767px){
    /* some CSS here */

    .text-kor{
        /* font-family: "Noto Sans KR"; */
        font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
        font-style: normal;
        font-weight: 400;
        z-index: 1;
    }

    .text-eng{
        /* font-family: "Noto Sans KR"; */
        font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
        font-style: normal;
        font-weight: 400;
        z-index: 1;
    }

    #home-window
    {
        position: relative;
        min-width: 200px;
        min-height: 100vh;

    }
    #main-vb-img{
        display: none;
    }
    #main-vb-img-mobile{
        display: contents;

        text-align: center;
        overflow: hidden;
    }
    #main-vb-img-mobile img{
        position: absolute;
        margin-top: 5%;
        height: 100%;
        width: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    #home-copyright-text
    {
        left: 25px;
        bottom: 20px;

        font-size: 0.5rem;
        line-height: 0.75rem;
    }

    #home-vb-img
    {
        position: absolute;
        width: 4rem;
        left: auto;
        right: 31px;
        bottom: 48px;
    }

    #menu-bar
    {

        z-index: 99;
        position: absolute;
        height: 36px;
        margin-top: 0px;
        text-align: right;
    }

    #menu-bar img{
        margin-right: 12px;
    }
    #menu-bar-tps{
        margin-left: 140px;
        margin-right: 0px;
    }

    #menu-bar-home {
        position: absolute;
        left: 12px;
    }


    #tps-content{
        margin-top: 150px;
    }
    #tps-content-logo-image{
        margin-left: 0;
        margin-bottom: 30px;
    }
    #tps-content-logo-image img {
        width: 80px;
    }

    #tps-text-kor{
        display: block;
        width: 100%;
        margin-top: 14px;
        margin-bottom: 40px;
        margin-right: 0;
        font-size: 14px;
        line-height: 22px;
    }

    #tps-text-eng{
        display: block;
        width: 100%;
        margin-top: 30px;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 72px;
        font-size: 14px;
        line-height: 20px;
    }

    #tps-text-insta{
        margin-top: 40px;
        font-size: 13px;
        line-height: 18px;
        margin-left: 0;
    }


    #vb-window
    {
        background-color: white;
        width: 100%;
        height: fit-content;
        position: absolute;
        min-height: 100%;
    }

    #vb-menu-bar
    {
        font-family: 'Plak W02 Black Condensed',Arial, Helvetica, 'sans-serif';
        height: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        margin-top: 70px;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;

        /* mobile screen fit menu to width */
        /* padding-left: 1.25rem;
        padding-right: 1.25rem;
        justify-content: space-between; */
        /* max-width: 480px; */
    }

    .vb-menu-bar-item
    {
        font-family: 'Plak W02 Black Condensed',Arial, Helvetica, 'sans-serif';
        cursor: pointer !important;
        color: #121212;
        font-size: 18px;
        font-style: normal;
        justify-content: space-between;
        /* font-weight: 800; */
    }

    .vb-menu-bar-blank
    {
        min-width: 1.2rem;

        /* mobile screen fit menu to width */
        /* display: none; */
    }

    .vb-menu-bar-item-selected
    {
        color: #5516CC !important;
    }

    #vb-about-content
    {
        width: 100%;
        margin-top: 60px;
        padding-left:30px;
        padding-right:30px;
    }

    #vb-about-content-wrapper{
        width:70%;
        float: none;
        margin: 0 auto;
    }

    #vb-about-content-letter img:nth-child(2){
        right: 50%;
    }
    #vb-about-content-letter img:nth-child(3){
        float: right;
    }
    #vb-about-text-wrapper{
        margin: 0 auto;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
    }
    #vb-about-content-specific img{
        width: 200px;
    }
    #vb-about-content-image img {
        margin-left: -8px;
        width: 88px;
        z-index: 2;
    }
    #vb-about-extra-text{
        margin-top: 40px;
        margin-left: 100px;
    }



    #vb-about-text-title-kor{
        font-weight: 700;
    }
    #vb-about-text-eng
    {
        position: relative;
        margin-bottom: 60px;
    }


    #vb-artist-content
    {
        width: 100%;
        margin-top: 0px;
        padding-top: 30px;
        padding-left:15px;
        padding-right: 15px;
        height:100%;
        height: fit-content;
        min-height: calc(100vh - 101px - 1rem);
    }

    #vb-artist-content-container{
        padding: 0;
        padding-bottom: 60px;
    }
    #vb-artist-content-info-container{
        margin-top: 20px;
    }
    #vb-artist-content-image-container{
        display: flex;
        flex-wrap: wrap;
    }

    .vb-artist-content-image-box{
        flex: 0 0 auto;
        /* 16 / 0.333 */
        /* 15.666 / 0.5 */
        /* 15 / 0.833 */
        width: 32.333%;
        margin: 0.5%;
        position:relative;
        overflow:hidden;
    }

    .vb-artist-content-image-box::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .vb-artist-content-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /* Video Bites - ARTIST - INFO */

    #vb-artist-content-info-container{
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    #vb-artist-content-info-back {
        position: absolute;
        color: #2d2d2d;
        font-size: 13px;
    }

    #vb-artist-content-info-back img{
        width: 34px;
    }
    
    #vb-artist-content-info-box{
        flex: 0 0 auto;
        width: 100%;
        position: relative;
        /* border: 1px solid red; */
        /* border-right: 2px dashed #E7DCFE; */
        padding-right: 0rem;
        height: 100%;
        margin-bottom: 10px;
    }
    
    #vb-artist-content-info-title{
        color: #000000;
        font-weight: normal;
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 20px;
        margin-top:4px;
    }
    
    #vb-artist-content-info-subtitle{

        color: #121212;
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 20px;
    }
    
    #vb-artist-content-info-text{
        display: block;
    }
    
    #vb-artist-content-info-birth{
        color: #7A7E84;
        font-size: 13px;
        line-height: 1rem;
        margin-bottom: 20px;
    }
    

    #vb-artist-content-info-about{
        width: 45%;
        float: left;
        color: #121212;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5rem;
        margin-bottom: 30px;
    }
    
    #vb-artist-content-info-history{
        width: 44%;
        float: right;
        color: #7A7E84;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 30px;
    }

    #vb-artist-content-info-homepage{
        width: 45%;
        /*float: left;*/
    }
    #vb-artist-content-info-homepage2{
        width: 50%;
        float: left;
    }
    
    #vb-artist-content-works-box{
        flex: 0 0 auto;
        width: 100%;
        /* border: 1px solid blue; */
        height: 100%;
        padding-left: 0rem;
        padding-top: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        /* align-items: stretch; */
        border-left: none;
    }
    
    #vb-artist-content-works-main-image{
        width: 100%;
        object-fit: contain;
        padding-top: 56.25%;
    }
    
    #vb-artist-content-works-main-image img{
        width: 100%;
        object-fit:contain;
    }
    
    #vb-artist-content-works-main-info{
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 100%;
        display: flex;
        padding-left: 0rem;
        margin-top: 0.75rem;
    }
    
    #vb-artist-content-works-main-reservation{

        font-size: 1rem;
        font-weight: 800;
        width:35%;
        text-align: center;
    }

    #vb-artist-content-null-box{
        display:none;
    }

    #vb-artist-content-works-booth{
        font-size: 0.5rem;
        line-height: 1.125rem;
        text-align: center;
    }
    
    #vb-artist-content-works-timetable{
        font-size: 1rem;
        line-height: 1.5rem;
        margin-top:0.25rem;
    }

    #vb-artist-content-works-timetable img{
        height: 1.25rem;
    }
    
    #vb-artist-content-works-main-caption{
        margin-top: 20px;
        font-size: 13px;
        line-height: 18px;
        font-weight: normal;
    }
    
    #vb-artist-content-works-text{
        width: 100%;
        margin-top: 20px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }
    
    #vb-artist-content-works-image-list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
    }
    
    .vb-artist-content-works-image-item{
        width: 100%;
        flex: 0 0 auto;
        width: 46.66%;
        margin-right: 3.33%;
        margin-bottom: 3.33%;
        position: relative;
    }
    
    .vb-artist-content-works-image-item::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    
    .vb-artist-content-works-image-item img{
        /* border: 1px solid black; */
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit:contain;
    }

    /* Video Bites - ARTIST - INFO - SPECIFIC */

    #vb-artist-content-works-specific-box{
        flex: 0 0 auto;
        width: 100%;
        /* border: 1px solid blue; */
        height: 100%;
        padding-left: 0rem;
        padding-top: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        /* align-items: stretch; */
        border-left: none;
        border-top: 1.2px solid #dee1e3;
    }

    #vb-artist-content-works-specific-image{
        width: 100%;
        object-fit: fill;
        margin-bottom: 20px;
    }

    #vb-artist-content-works-specific-image img{
        width: 100%;
        object-fit: fill;
    }

    #vb-artist-content-works-specific-info{
        flex-direction: column;
        width: 70%;
        display: flex;
        padding-right: 1rem;
    }

    #vb-artist-content-works-specific-contact-box{
        margin-top: 28px;
        width: 100%;
        text-align: left;
    }



    #vb-artist-content-works-specific-contact img{
        height: 1.25rem;
    }

    #vb-artist-content-works-specific-caption{
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
    }

    #vb-artist-content-works-specific-price{
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: normal;
        color: #000000;
        margin-top: 20px;
    }


    /* VB - ARCHIVE */

    #vb-archive-content-container{
        display: flex;
        flex-wrap: wrap;
    }

    #vb-archive-content-title{
        flex: 0 0 auto;
        width: 100%;
        font-size: 30px;
        color: #000000;
        margin-bottom: 30px;
        text-align: left;
    }

    .vb-archive-content-image {
        cursor: pointer;
        float: left;
        width: 240px;
        height: 160px;
        margin-right: 20px;
        margin-bottom: 30px;
        object-fit: cover;
    }

    .vb-archive-content-image-box {
        position: relative;
        object-fit: cover;
        /* border: 1px solid gray; */
    }
    .vb-archive-content-image-box::after {
        content: "";
        display: block;
    } 



    .vb-archive-content-item-title {
        color: #7A7E84;
        margin-left: 40px;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .vb-archive-content-item-date {
        width: 90%;
        margin-top: 24px;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }

    .vb-archive-content-item-platform {
        margin-top: 4px;
        display: block;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2rem;
    }


    /* VB - PROGRAM */


    #vb-program-content
{
    margin-top: 0px;
    background-color: white;
    padding-top: 0px;
    padding-left:0px;
    padding-right:0px;
    margin-right: 15px;
    margin-left: 15px;
    height:100%;
    height: fit-content;
    /*min-height: calc(100vh - 101px - 1rem);*/
    font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
}

#vb-program-box {
    padding-left: 0px;
}
#vb-program-title {
    font-family: 'Plak W02 Black Condensed',Arial, Helvetica, 'sans-serif';
    font-size: 32px;
    line-height: 36px;
    color: #121212;
}
#vb-program-contents{
    width: 80%;
}
#vb-program-name p{
    width:80%;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-top: 2px;
    margin-left: 5px;
}
#vb-program-contents {
    margin-top: 20px;
}
#vb-program-info {
    font-size: 13px;
    width:100%;
    padding-left: 34px;
    margin-top: 36px;
    margin-bottom: 18px;
}
#vb-program-info li {
    display: inline-block;
    width: 90%;
    margin-bottom: 0px;
}
#vb-program-info p {
    margin-bottom: 14px;
}
}







/*--------------------------------------------------------------------------------*/

/* #### Tablets Portrait or Landscape #### */
/* (min-width: 768px) */
@media screen and (min-width: 768px) and (max-width: 1024px){
    /* some CSS here */

    #home-copyright-text
    {
        left: 43px;
        bottom: 44px;

        font-size: 1.125rem;
        line-height: 0.75rem;
    }


    #menu-bar
    {
        z-index: 99;
        position: absolute;
        height: 22px;
        margin-top: 24px;
    }
    #menu-bar img{
        margin-right: 12px;
        margin-left: 12px;
    }


    #tps-window
    {
        background-color: #9affd8;
        width: 100%;
        height: 100%;
        position: absolute;
        
    }
    #tps-content{
        margin-top: 150px;
        padding-left: 18%;
        padding-right:18%;
    }

    #tps-content-logo-image{
        margin-left: 0;
        margin-bottom: 24px;
    }
    #tps-content-logo-image img {
        width: 80px;
    }

    #tps-text-kor{
        display: block;
        width: 100%;
        margin-top: 14px;
        margin-bottom: 40px;
        margin-right: 0;
        font-size: 14px;
        line-height: 22px;
    }

    #tps-text-eng{
        display: block;
        width: 100%;
        margin-top: 30px;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 72px;
        font-size: 14px;
        line-height: 20px;
    }

    #tps-text-insta{
        margin-top: 40px;
        font-size: 13px;
        line-height: 18px;
        margin-left: 0;
    }

    #vb-window
    {
        background-color: white;
        width: 100%;
        height: fit-content;
        position: absolute;
        min-height: 100%;
    }

    #vb-menu-bar
    {
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        margin-top: 80px;
        margin-bottom: 30px;
    }

    .vb-menu-bar-item
    {
        cursor: pointer !important;
        color:#121212;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
    }

    .vb-menu-bar-blank
    {
        min-width: 2rem;
    }

    .vb-menu-bar-item-selected
    {
        color: #5516CC !important;
    }



    #vb-about-content
    {
        width: 100%;
        margin-top: 30px;
        padding-left:100px;
        padding-right:100px;
    }

    #vb-about-content-wrapper{
        width:70%;
        float: none;
        margin: 0 auto;

    }

    #vb-about-content-letter img:nth-child(2){
        right: 50%;
    }
    #vb-about-content-letter img:nth-child(3){
        float: right;
    }
    #vb-about-text-wrapper{
        margin: 0 auto;
        margin-top: 30px;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
    }
    #vb-about-content-specific img{
        width:140px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    #vb-about-extra-text{
        clear: both;
        margin-top: 40px;
        margin-left: 100px;
    }



    #vb-about-text-title-kor{
        font-weight: 700;
    }
    #vb-about-text-eng
    {
        position: relative;
        margin-bottom: 60px;
    }


    #vb-artist-content
    {
        margin-top: 0px;
        margin-left: 2rem;
        margin-right: 2rem;
        padding-top: 30px;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        height:100%;
        height: fit-content;
        min-height: calc(100vh - 161px);
    }

    #vb-artist-content-container{
        padding: 0;
        /* border: 1px solid red; */
        padding-bottom: 60px;
    }

    #vb-artist-content-image-container{
        display: flex;
        flex-wrap: wrap;
    }

    .vb-artist-content-image-box{
        flex: 0 0 auto;
        /* 16 / 0.333 */
        /* 15.666 / 0.5 */
        /* 15 / 0.833 */
        width: 24%;
        margin: 0.5%;
        position:relative;
        overflow:hidden;
    }

    .vb-artist-content-image-box::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .vb-artist-content-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /* Video Bites - ARTIST - INFO */

    #vb-artist-content-info-container{
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        margin-top: 20px;
    }
    
    #vb-artist-content-info-back {
        position: absolute;
        margin-top: -50px;
        color: #2d2d2d;
        font-weight: 400;
        font-size: 15px;
    }
    
    #vb-artist-content-info-box{
        flex: 0 0 auto;
        width: 100%;
        position: relative;
        /* border: 1px solid red; */
        /* border-right: 2px dashed #E7DCFE; */
        padding-right: 0rem;
        height: 100%;
    }
    
    #vb-artist-content-info-title{
        color: #000000;
        font-style: normal;
        font-weight: normal;
        font-size: 2.125rem;
        line-height: 2.125rem;
        margin-bottom: 30px;
        margin-top:4px;
    }
    
    #vb-artist-content-info-subtitle{
        color: black;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 30px;
    }
    
    #vb-artist-content-info-text{
        display: block;
    }
    
    #vb-artist-content-info-birth{
        color: #7A7E84;
        font-weight: 300;
        font-size: 14px;
        line-height: 1.5rem;
        margin-bottom: 30px;
    }
    
    #vb-artist-content-info-about{
        width: 50%;
        float: left;
        color: black; 
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 30px;
    }
    
    #vb-artist-content-info-history{
        width: 45%;
        float: right;
        color: #7A7E84;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5rem;
        margin-bottom: 30px;
    }
    #vb-artist-content-info-homepage{
        width: 48%;
        /*float: left;*/
    }
    #vb-artist-content-info-homepage2{
        width: 48%;
        /*float: left;*/
    }
    #vb-artist-content-works-box{
        flex: 0 0 auto;
        width: 100%;
        /* border: 1px solid blue; */
        height: 100%;
        padding-left: 0rem;
        padding-top: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        /* align-items: stretch; */
        border-left: none;
    }
    
    #vb-artist-content-works-main-image{
        width: 100%;
        object-fit: contain;
    }
    
    #vb-artist-content-works-main-image img{
        width: 100%;
        object-fit: contain;
    }
    
    #vb-artist-content-works-main-info{
        display: inline-block;
        width: 100%;

    }
    
    #vb-artist-content-works-main-reservation{

        font-size: 1rem;
        font-weight: 800;

    }
    
    #vb-artist-content-works-main-caption{
        width: 100%;
        align-items: flex-end;
        margin-top: 20px;
        font-size: 13px;
        line-height: 1.125rem;
        font-weight: normal;
    }
    
    #vb-artist-content-works-text{

        width: 100%;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }
    
    #vb-artist-content-works-image-list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
    }
    
    .vb-artist-content-works-image-item{
        width: 100%;
        flex: 0 0 auto;
        width: 30%;
        margin-right: 3.33%;
        margin-bottom: 3.33%;
        position: relative;
    }
    
    .vb-artist-content-works-image-item::after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
    
    .vb-artist-content-works-image-item img{
        /* border: 1px solid black; */
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit:contain;
    }

    /* Video Bites - ARTIST - INFO - SPECIFIC */

    #vb-artist-content-works-specific-box{
        flex: 0 0 auto;
        width: 100%;
        /* border: 1px solid blue; */
        height: 100%;
        padding-left: 0rem;
        padding-top: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        /* align-items: stretch; */
        border-left: none;
        border-top: 1.2px solid #dee1e3;
    }

    #vb-artist-content-works-specific-image{
        width: 100%;
        object-fit: fill;
        margin-bottom: 30px;
    }

    #vb-artist-content-works-specific-image img{
        width: 100%;
        object-fit: fill;
    }

    #vb-artist-content-works-specific-info{
        flex-direction: column;
        width: 70%;
        display: flex;
        padding-right: 1rem;
    }

    #vb-artist-content-works-specific-contact-box{
        width: 30%;
        display: flex;
    }

    #vb-artist-content-works-specific-caption{
        font-size: 13px;
        line-height: 1.125rem;
        font-weight: normal;
    }

    #vb-artist-content-works-specific-price{
        font-size: 1.25rem;
        line-height: 2rem;
        font-weight: normal;
        color: #000000;
        margin-top: 30px;
    }


    /* VB - ARCHIVE */

    #vb-archive-content-container{
        display: flex;
        flex-wrap: wrap;
    }

    #vb-archive-content-title{
        flex: 0 0 auto;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 2rem;
        line-height: 2rem;
        color: #000000;
        margin-bottom: 30px;
    }

    .vb-archive-content-item {
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 2rem;
        position: relative;
        overflow: hidden;
        /* border: 1px solid black; */
        text-decoration: none;
        color: black;
    }

    .vb-archive-content-image-box {
        position: relative;
        object-fit: cover;
        /* border: 1px solid gray; */
    }
    .vb-archive-content-image-box::after {
        content: "";
        display: block;
    } 

    .vb-archive-content-image{
    cursor: pointer;
    float: left;
    width: 300px;
    height: 200px;
    margin-right: 30px;
    object-fit: cover;
    }

    .vb-archive-content-item-title {
        color: #7A7E84;
        margin-left: 40px;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 20px;
    }

    .vb-archive-content-item-date {
        margin-top: 30px;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }

    .vb-archive-content-item-platform {

        display: block;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2rem;
    }

    /* VB - PROGRAM */

    #vb-program-content
    {
        max-width: 92%;
        background-color: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 60px;
        padding-left: 1%;
        padding-right: 1%;
        height: 100%;
        height: fit-content;
        font-family: 'Spoqa Han Sans Neo',Arial, Helvetica, 'sans-serif';
        /*min-height: calc(100vh - 161px);*/

    }
    #vb-program-box {
        padding-left: 0px;
    }

    #vb-program-title {
        font-family: 'Plak W02 Black Condensed',Arial, Helvetica, 'sans-serif';
        font-size: 32px;
        line-height: 36px;
        color: #121212;
    }
    #vb-program-contact {
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-top:60px;
    }
    #vb-program-name {
        width:80%;
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        margin-top: 24px;
    }
    #vb-program-contents {
        margin-top: 24px;
    }
    #vb-program-info {
        font-size: 13px;
        width:100%;
        padding-left: 24px;
        margin-top: 36px;
        margin-bottom: 18px;
    }
    #vb-program-info li {
        display: inline-block;
        width: 48%;
        margin-bottom: 0px;
        margin-left: 10px;
    }
    #vb-program-info p {
        margin-bottom: 16px;
    }



}






/***************************************************************************************/


@media screen and (min-width: 200px) and (max-width: 295px){
#menu-bar-vb{
    margin-top: -4px !important;
}
#vb-menu-bar{
    margin-top: 120px !important;
}
}




@media screen and (min-width: 200px) and (max-width: 390px){
    #tps-window {
        background-color: #9affd8;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    #tps-content{
        padding-left: 15px;
        padding-right: 15px;
    }

    #menu-bar {
        z-index: 99;
        position: absolute;
        height: 22px;
        margin-top: 20px;
        text-align: right;
    }
    #menu-bar img{
        margin-left: 0px;
        margin-right:15px;
        margin-bottom: 10px;
    }
    #menu-bar-tps{
        position: absolute;
        right: 0px;
        
    }
    #menu-bar-vb-2021{
        margin-top: 28px;
        
    }
    #menu-bar-vb{
        margin-top: 28px;
    }

    #vb-menu-bar{
        margin-top:100px;
    }
    #vb-about-content
    {
        margin-top: 32px;
        padding-left:20px;
        padding-right:20px;
    }


    #vb-about-content-wrapper{
        width:80%;
        margin: 0 auto;

    }
    #vb-about-content-letter img{
        width: 100%;
    }
    #vb-about-content-letter img:nth-child(1){
        width: 78%;
    }
    #vb-about-content-letter img:nth-child(2){
        margin-left: 0px;
        right: unset;
    }
    #vb-about-content-specific{
        margin-bottom: 30px;
    }
    #vb-about-content-specific img{
        width:44%;
        margin-top: 0px;
        margin-bottom: 10px;
    }
    #vb-about-text-wrapper{
        margin: 0 auto;
        margin-top: 30px;
        padding-left: 0px;
        padding-right: 0px;
    }
    #vb-about-extra-text{
        width: 100%;
        margin-top: 40px;
        margin-left: 0px;
    }
    #vb-about-text-title-kor{
        font-weight: 700;
    }
    #vb-about-text-eng
    {
        position: relative;
        margin-bottom: 60px;
    }

    #vb-artist-content {
        width: 100%;
        margin-top: 0px;
        padding-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
        height: 100%;
        height: fit-content;
        min-height: calc(100vh - 101px - 1rem);
    }
    

    #vb-artist-content-info-about{
        width: 100%;
        margin-bottom: 30px;
    }
    
    #vb-artist-content-info-history{
        width: 100%;

        margin-bottom: 30px;
    }
    #vb-artist-content-info-homepage {
        width: 100%;
    }
    #vb-artist-content-info-homepage2 {
        width: 100%;
    }

    #vb-artist-content-works-contact-box {
        width: 100%;
        justify-content: flex-end;
    }


    #vb-artist-content-works-specific-info {
        flex-direction: column;
        width: 100%;
        display: flex;
        padding-right: 1rem;
    }

    #vb-artist-content-works-specific-contact-box {

        align-items: flex-end;
    }


    .vb-archive-content-item {
        width: 100%;
        clear: both;
        margin-bottom: 34px;
        position: relative;
        overflow: hidden;
    } 

    .vb-archive-content-item-title {
        color: #7A7E84;
        margin-left: 0px;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .vb-archive-content-item-date {
        margin-top: 10px;
        display: block;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .vb-archive-content-image {
        display: block;
        text-align: center;
        cursor: pointer;
        width: 180px;
        height: 120px;
        margin-right:130px;
        margin-bottom:12px;
        object-fit: cover;
    }
}

@media screen and (min-width: 391px) and (max-width: 430px){
    .vb-archive-content-item-title {
        color: #7A7E84;
        margin-left: 0px;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .vb-archive-content-item-date {
        margin-top: 10px;
        display: block;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .vb-archive-content-image {
        text-align: center;
        cursor: pointer;
        width: 240px;
        height: 160px;
        margin-right: 132px;
        margin-bottom: 8px;
        object-fit: cover;
    }
}





@media screen and (min-width: 391px) and (max-width: 600px){
    #tps-window {
        background-color: #9affd8;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    #tps-content{
        padding-left: 20px;
        padding-right: 20px;
    }

    #menu-bar {
        z-index: 99;
        position: absolute;
        height: 24px;
        margin-top: 20px;
        text-align: right;
    }
    #menu-bar img{
        margin-left: 0px;
        margin-right:15px;
        margin-bottom: 10px;
    }
    #menu-bar-tps{
        position: absolute;
        right: 0px;
        
    }
    #menu-bar-vb-2021{
        margin-top: 32px;
        
    }
    #menu-bar-vb{
        margin-top: 32px;
    }

    #vb-menu-bar{
        margin-top:100px;
    }
    #vb-about-content
    {
        margin-top:32px;
        padding-left:20px;
        padding-right:20px;
    }

    #vb-about-content-wrapper{
        width: 300px;
        margin: 0 auto;

    }
    #vb-about-content-letter img{
        width: 300px;
    }
    #vb-about-content-letter img:nth-child(2){
        margin-left: 0px;
        right: unset;
    }
    #vb-about-content-specific{
        margin-bottom: 30px;
    }
    #vb-about-content-specific img{
        width:44%;
        margin-top: 0px;
        margin-bottom: 10px;
    }
    #vb-about-text-wrapper{
        margin: 0 auto;
        margin-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }
    #vb-about-extra-text{
        width: 90%;
        margin-top: 40px;
        margin-left: 15px;
    }
    #vb-about-text-eng
    {
        position: relative;
        margin-bottom: 60px;
    }

    
    #vb-artist-content-info-about{
    width: 100%;
    }
    
    #vb-artist-content-info-history {
        width: 100%;
    }

    #vb-artist-content-info-homepage {
        width: 100%;
    }
    #vb-artist-content-info-homepage2 {
        width: 100%;
    }

    #vb-artist-content-works-specific-info {
        flex-direction: column;
        width: 100%;
        display: flex;
        padding-right: 1rem;
    }
    .vb-archive-content-item-date {
        width: 90%;
        margin-top: 10px;
        display: block;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
    }
    .vb-archive-content-item-title {
        color: #7A7E84;
        margin-left: 0px;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 6px;
    }
    .vb-archive-content-image {
        text-align: center;
        cursor: pointer;
        width: 240px;
        height: 160px;
        margin-right: 264px;
        margin-bottom: 16px;
        object-fit: cover;
    }



}

@media screen and (min-width:601px) and (max-width: 767px){
    #menu-bar {
        z-index: 99;
        position: absolute;
        height: 22px;
        margin-top: 20px;
        text-align: center;
    }
    #menu-bar img{
        margin-right: 10px;
        margin-left: 10px;
    }
    #vb-menu-bar{
        margin-top: 80px;
    }
    #menu-bar-tps {
        margin-left: 0px;
    }    
    #vb-about-content
    {
        margin-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
    }
    #vb-about-content-wrapper{
        width: 300px;
        margin: 0 auto;

    }
    #vb-about-content-letter img{
        width: 300px;
    }
    #vb-about-content-letter img:nth-child(2){
        margin-left: 0px;
        right: unset;
    }
    #vb-about-content-specific{
        margin-bottom: 30px;
    }
    #vb-about-content-specific img{
        width:44%;
        margin-top: 0px;
        margin-bottom: 10px;
    }
    #vb-about-text-wrapper{
        margin: 0 auto;
        margin-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
    }
    #vb-about-extra-text{
        width: 90%;
        margin-top: 40px;
        margin-left: 50px;
    }
    #vb-about-text-title-kor{
        font-weight: 700;
    }
    #vb-about-text-eng
    {
        position: relative;
        margin-bottom: 60px;
    }
    #vb-artist-content{
        padding-left: 20px;
        padding-right: 20px;
    }
    #vb-artist-content-info-about {
        width: 50%;
        float: left;
        color: black;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5rem;
        margin-bottom: 30px;
    }

    #vb-artist-content-info-history {
        width: 45%;
        float: right;
        color: #7A7E84;
        font-weight: 400;
        font-size: 13px;
        line-height:22px;
        margin-bottom: 30px;
    }


}
















